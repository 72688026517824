import React from 'react'

const HomeBanner = () => {
  return (
    <div className="home-banner montserrat">
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-xl-6'>
            <h1 className='montserrat hm-bnr-title'>
              <b><span>AI</span></b> Enabled <b><span>Careers</span></b> with Experiential Learning
            </h1>
            <div className='mt-4'>
              <div className='d-flex'>
                <div className='banner-icon-btn'>
                  <img src='../../images/for-college.svg' />
                  For Colleges
                </div>
                <a href='#college' className='site-btn montserrat'>Enhance Placements</a>
              </div>
              <div className='d-flex my-3'>
                <div className='banner-icon-btn'>
                  <img src='../../images/talent.svg' />
                  For Companies
                </div>
                <a href='#company' className='site-btn montserrat'>Hire Top Talent</a>
              </div>
              <div className='d-flex'>
                <div className='banner-icon-btn'>
                  <img src='../../images/individuals.svg' />
                  For Students
                </div>
                <a href='#individual' className='site-btn montserrat'>Get Discovered</a>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-5 col-xl-6 d-flex align-items-center justify-content-center'>
            <div className='d-flex justify-content-center align-items-center position-relative background'>
              <div className='center-img d-flex align-items-center justify-content-center'>
                <div className='mn-center-icon'><img src='../../images/bci.svg' /></div>
                <div className='small-icons position-absolute h-100 w-100'>
                  <div className='mn-side-circle bi1'><img src='../../images/bi1.svg' /></div>
                  <div className='mn-side-circle bi2'><img src='../../images/bi2.svg' /></div>
                  <div className='mn-side-circle bi3'><img src='../../images/bi3.svg' /></div>
                  <div className='mn-side-circle bi4'><img src='../../images/bi4.svg' /></div>
                  <div className='mn-side-circle bi5'><img src='../../images/bi5.svg' /></div>
                  <div className='mn-side-circle bi6'><img src='../../images/bi6.svg' /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeBanner;