import React from 'react'
const HiringBanner = () => {
    return (
        <div className='featured-cards hiring-banner element-space-40 montserrat'>
            <div className='top-offering-sec bg-img'>
                <h5>Find the Perfect Hire in Half the Time</h5>
                <div className='dp-content'>
                    <ul>
                        <li><img src='../../images/check.svg' />1-2 interviews only to hire the right candidate</li>
                        <li><img src='../../images/check.svg' />Save up to 70% of your hiring time</li>
                        <li><img src='../../images/check.svg' />Boost team productivity by reducing recruiter workload</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HiringBanner;