import kapishImg from "../Assets/our-team/kapish-saraf.png"
import amritanshuImg from "../Assets/our-team/amritanshu-kumar.png"
import bhavikImg from "../Assets/our-team/bhavik.jpg"
import adityaImg from "../Assets/our-team/aditya.png"

export const TeamMembers = [
  {
    id: 1,
    name: "Kapish Saraf",
    designation: "Founder & Director",
    text: "IIT Kharagpur, IIM Calcutta",
    image: kapishImg,
    linkedInUrl: "https://www.linkedin.com/in/kapish-saraf-75b4a710/"
  },
  {
    id: 2,
    name: "Amritanshu Thakur",
    designation: "Founder & Director",
    text: "IIT Kharagpur, IIM Calcutta",
    image: amritanshuImg,
    linkedInUrl: "https://www.linkedin.com/in/amritanshuthakur/"
  },
  {
    id: 3,
    name: "Bhavik Khurana",
    designation: "Founder's Office",
    text: "Intel, Atal Tinkering Labs",
    image: bhavikImg,
    linkedInUrl: "https://www.linkedin.com/in/bhavik-khurana/"
  },
  {
    id: 4,
    name: "Aditya Borwankar",
    designation: "Founder's Office",
    text: "IIT BHU (Varanasi), Fractal",
    image: adityaImg,
    linkedInUrl: "https://www.linkedin.com/in/aditya-borwankar-880749159/"
  }
]

export const awardsData = [
  {
    imageSrc: "./svg/award-recg-1.svg"
  },
  {
    imageSrc: "./svg/award-recg-2.svg"
  },
  {
    imageSrc: "./svg/award-recg-3.svg"
  },
  {
    imageSrc: "./svg/award-recg-4.svg"
  },
  {
    imageSrc: "./svg/award-recg-5.svg"
  },
  {
    imageSrc: "./svg/award-recg-6.svg"
  },
  {
    imageSrc: "./svg/award-recg-7.svg"
  },
  {
    imageSrc: "./svg/award-recg-8.svg"
  },
  {
    imageSrc: "./svg/award-recg-9.jpg"
  },
];