import React from 'react'
const Offer = () => {
    return (
        <div className='featured-cards element-space-40 montserrat'>
            <div className='container'>
                <div className='top-offering-sec'>
                    <div className='top-offering-txt'>
                        <h5 className="offering-tag">Top  Offering</h5>
                        <div className='d-block d-md-none'>
                            <h2>Digital Portfolio</h2>
                            <p>Trusted by Colleges to Increase Placement Success</p>
                        </div>
                        <div className='d-none d-md-block'>
                            <img className="card-img-top" src="../../images/bag.svg" alt="Bag" />
                            <p>Empower your students with personalized <span>Skill portfolios</span> designed to showcase their unique talents and achievements to top recruiters.</p>
                        </div>
                    </div>
                    <div className='dp-main'>
                        <div className='dp-circle'>
                            <div className='d-none d-md-block'>
                                <h2>Digital <br />Portfolio</h2>
                                <p>Trusted by Colleges to Increase Placement Success</p>
                            </div>
                            <div className='d-block d-md-none text-center'>
                                <img className="card-img-top" src="../../images/bag_white.svg" alt="Bag" />
                                <p className='circle-mob-txt'>Empower your students with personalized <span>Skill portfolios</span> designed to showcase their unique talents and achievements to top recruiters.</p>
                            </div>
                        </div>
                        <div className='dp-content'>
                            <ul>
                                <li><img src='../../images/check.svg' /> Stand Out from the Crowd</li>
                                <li><img src='../../images/check.svg' /> Attract More Recruiters</li>
                                <li><img src='../../images/check.svg' /> Boost Placement Success</li>
                            </ul>
                            <div className='text-end'><img src='../../images/dp-img.svg' /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Offer;