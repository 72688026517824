import React from 'react'
const HomeFeatureCard = () => {
    return (
        <div className='featured-cards element-space-40 montserrat'>
            <div className='container'>
            <div className='row'>
                <div className='col-6 col-md-3 mb-3 mb-md-0'>
                    <div className="card card-animation">
                        <img className="card-img-top" src="../../images/interview-readiness.svg" alt="Interview Readiness" />
                        <div class="card-body">
                            <h5 className="card-title">Interview Readiness</h5>
                            <p className="card-text">Personalized training for each student to crack their dream job</p>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-md-3 mb-3 mb-md-0'>
                    <div className="card card-animation">
                        <img className="card-img-top" src="../../images/student-skill-certification.svg" alt="Student Skill Certification" />
                        <div class="card-body">
                            <h5 className="card-title">Student Skill Certification</h5>
                            <p className="card-text">Industry recognized certification for niche skills</p>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-md-3 mb-3 mb-md-0'>
                    <div className="card card-animation">
                        <img className="card-img-top" src="../../images/AI-assessments.svg" alt="AI Assessments" />
                        <div class="card-body">
                            <h5 className="card-title">AI Assessments</h5>
                            <p className="card-text">Skill profiling of student leveraging proprietary AI platform </p>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-md-3 mb-3 mb-md-0'>
                    <div className="card card-animation">
                        <img className="card-img-top" src="../../images/digital-mentoring.svg" alt="Digital Mentoring" />
                        <div class="card-body">
                            <h5 className="card-title">Digital Mentoring</h5>
                            <p className="card-text">Digital library of experiences of mentors across industries</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default HomeFeatureCard;