import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="footer" id="contact">
      <div className="container">
        <div className="row">
          <div className="left-sec col-12 col-sm-6 col-md-4 col-lg-4 mt-5">
            <div className="part-1">
              <div className="logo">
                <img src="/images/logo.svg" alt="logo" />
              </div>
              <div className="dscptn">
                <h6>A KidEx Venture Private Limited company</h6>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-4 footer-contact-us">
            <div className="part-2">
              <ul className="ps-0">
                <li className="fw-bold mb-2">Contact us</li>
                <li className="mb-2">
                  <a href="mailto:info@kid-ex.com">
                    <span
                    ><i
                      className="fa fa-envelope-o pe-2"
                      aria-hidden="true"
                    ></i></span
                    >info@kid-ex.com</a
                  >
                </li>
                <li>
                  <span><i className="fa fa-whatsapp fa-lg pe-2" aria-hidden="true"></i></span>+91 73031 50200
                </li>
              </ul>
            </div>
          </div>
          <div className="right-sec col-12 col-sm-12 col-md-5 col-lg-4 mt-5">
            <ul>
              <li>
                <a
                  href="https://maps.app.goo.gl/3YLViU2hmGiMdsZW9"
                  target="_blank"
                  rel="noreferrer"
                ><span
                ><i
                  className="fa fa-map-marker me-2 mb-3"
                  aria-hidden="true"
                ></i></span
                  >Locate us on Google</a
                >
              </li>
              <li>
                <div className="map master-naukri-map">
                  <iframe
                    title='map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1749412467366!2d77.0715452!3d28.4139777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23003fdc9ec7%3A0xe7a1ca63bf36f1ad!2sKidEx%20Venture%20Private%20Limited!5e0!3m2!1sen!2sau!4v1733137749275!5m2!1sen!2sau"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </li>
              <li className="mt-4">
                <h6>409, 4th Floor, Tower C, Unitech Business Zone, Sector 50, Gurugram, Haryana</h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="terms-conditions">
        <div className="container">
          <p className="copy-right-content">
            <span>©</span> 2022 by KidEx Venture Pvt. Ltd.
          </p>
          <p className="terms-con-link">
            <Link to="/tnc">Terms and Conditions</Link>
            <Link to="/ppc">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer