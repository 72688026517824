import React from 'react'
import Slider from "react-slick";
import logo1 from "../../../Assets/partner-logos/IIM_SIrmaur_logo.png"
import logo2 from "../../../Assets/partner-logos/Persistent_Foundation.jpg"
import logo3 from "../../../Assets/partner-logos/banasthali_vidyapith_logo.jpg"
import logo4 from "../../../Assets/partner-logos/Vedica_Logo.jpg"
import logo5 from "../../../Assets/partner-logos/Matrix_Schools_Logo.png"
import logo6 from "../../../Assets/partner-logos/Medhavi_Skills_Universiry_Logo.png"
import logo7 from "../../../Assets/partner-logos/Growth_Maximisers_Logo.jpg"
import logo8 from "../../../Assets/partner-logos/Atlys_Logo.png"
import logo9 from "../../../Assets/partner-logos/Orbit_Farming_Logo.png"

const settings = {
  speed: 5000,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: false,
  cssEase: 'linear',
  slidesToShow: 4,
  draggable: false,
  focusOnSelect: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    }
  ],
};





const ClientCarousel = () => {
  return (
    <div className='logo-carousel'>
      <Slider {...settings}>
        <div className='logo-outer'><img src={logo1} /></div>
        <div className='logo-outer'><img src={logo2} /></div>
        <div className='logo-outer'><img src={logo3} /></div>
        <div className='logo-outer'><img src={logo4} /></div>
        <div className='logo-outer'><img src={logo5} /></div>
        <div className='logo-outer'><img src={logo6} /></div>
        <div className='logo-outer'><img src={logo7} /></div>
        <div className='logo-outer'><img src={logo8} /></div>
        <div className='logo-outer'><img src={logo9} /></div>
      </Slider>
    </div>
  )
}

export default ClientCarousel;