import React from 'react'
import { Link } from 'react-router-dom';
const HomeFeatureCardSkill = () => {
    return (
        <div className='featured-cards horizontal-card montserrat'>
            <div className='container'>
                <div className='row'>
                    <div className='col-6 col-md-4 mb-4'>
                        <div className="card card-animation d-flex flex-column-reverse flex-md-row">
                            <div class="card-body p-0">
                                <h5 className="card-title">Digital Portfolio</h5>
                                <p className="card-text text-start">Showcase your skills to recruiters for jobs/internships</p>
                            </div>
                            <img className="card-img-top" src="../../images/digital-portfolio.svg" alt="Interview Readiness" />
                        </div>
                    </div>
                    <div className='col-6 col-md-4 mb-4'>
                        <div className="card card-animation d-flex flex-column-reverse flex-md-row">
                            <div class="card-body p-0">
                                <h5 className="card-title">Interview Readiness</h5>
                                <p className="card-text text-start">Personalized training to crack your dream job</p>
                            </div>
                            <img className="card-img-top" src="../../images/interview-readiness.svg" alt="Interview Readiness" />
                        </div>
                    </div>
                    <div className='col-6 col-md-4 mb-4'>
                        <div className="card card-animation d-flex flex-column-reverse flex-md-row">
                            <div class="card-body p-0">
                                <h5 className="card-title">Skill Certifications</h5>
                                <p className="card-text text-start">Industry recognized certifications for niche skills</p>
                            </div>
                            <img className="card-img-top" src="../../images/skill-certification.svg" alt="Interview Readiness" />
                        </div>
                    </div>
                    <div className='col-6 col-md-4 mb-4'>
                        <div className="card card-animation d-flex flex-column-reverse flex-md-row">
                            <div class="card-body p-0">
                                <h5 className="card-title">AI-Assessments</h5>
                                <p className="card-text text-start">Skill profiling leveraging proprietary AI platform</p>
                            </div>
                            <img className="card-img-top" src="../../images/ai-assessments-icon.svg" alt="Interview Readiness" />
                        </div>
                    </div>
                    <div className='col-6 col-md-4 mb-4'>
                        <div className="card card-animation d-flex flex-column-reverse flex-md-row">
                            <div class="card-body p-0">
                                <h5 className="card-title">Corporate Competitions</h5>
                                <p className="card-text text-start">Compete to win rewards and placement opportunities</p>
                            </div>
                            <img className="card-img-top" src="../../images/corporate-competitions.svg" alt="Corporate Competitions" />
                        </div>
                    </div>
                    <div className='col-6 col-md-4 mb-4'>
                        <div className="h-100 d-flex flex-column justify-content-center card-btn-col">
                            <div class="card-body p-0">
                                <h5 className="card-title text-center mb-3">Expedite your career journey now!</h5>
                                <Link to='/programs' className='site-btn blue-btn text-decoration-none'>Explore Programs</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFeatureCardSkill;