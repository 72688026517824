import React from 'react'
import { Link } from 'react-router-dom';
import { androidLink, iosLink } from '../../../Redux/Actions/config';
import masterNaukri from "../../../Assets/images/masterNaukriApp.png";

const ApplicationBanner = () => {
    return (
        <div className='hiring-banner montserrat pt-3'>
            <div className='container'>
                <div className='top-offering-sec phone-bg'>
                    <h5>Join 10,000+ professionals and students who trust us for their career growth!</h5>
                    <p className='my-0 my-md-3'>Download the masterNaukri app now!</p>
                    <div className='d-flex flex-column-reverse flex-md-row align-items-start align-items-md-end'>
                        <div className='mn-app-logo'>
                            <Link to={androidLink} target="_blank" className='d-md-inline-block'><img src='../../images/gp-img.svg' className='' /></Link>
                            <Link to={iosLink} target="_blank" className='d-md-inline-block'><img src='../../images/ap-img.svg' className='me-0 ms-2 mx-md-3' /></Link>
                        </div>
                        <img src={masterNaukri} className='d-inline-block mx-0 mx-md-auto mt-3 mt-md-0' width={80} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ApplicationBanner;