import React from 'react'
import Slider from "react-slick";
import { awardsData } from '../../../helper/data';


const settings = {
  speed: 5000,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: false,
  cssEase: 'linear',
  slidesToShow: 4,
  draggable: false,
  focusOnSelect: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
  rtl: true,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    }
  ],
};

const Awards = () => {

  return (
    <div className='logo-carousel'>
      <Slider {...settings}>
        {awardsData?.map(item => {
          return <div className='logo-outer-award'><img src={item?.imageSrc} logo="logo" /></div>
        })}
      </Slider>
    </div>
  )
}

export default Awards
