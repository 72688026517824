import React from 'react'
import HiringBanner from './HiringBanner';
const HomeFeatureImageDetails = () => {
    return (
        <div className='featured-cards  element-space-40 feature-image-card-details montserrat'>
            <div className='container'>
                <div className='row element-space-40'>
                    <div className='col-md-7 pe-md-5'>
                        <img src='../../images/freshar-hiring.svg' className='mw-100' />
                    </div>
                    <div className='col-md-5 mt-4 mt-md-0'>
                        <div className="card p-0">
                            <div className='mb-4'>
                                <h5 className="card-title">Digital Portfolio</h5>
                                <p className="card-text">Live, Free database of available candidates with skill profiling</p>
                            </div>
                            <div className='mb-4'>
                                <h5 className="card-title">Campus Management</h5>
                                <p className="card-text">Access to 1,000+ colleges nationwide with complete market intelligence</p>
                            </div>
                            <div className=''>
                                <h5 className="card-title">Skill Competitions</h5>
                                <p className="card-text">Conduct own competitions to identify relevant talent & out of the box ideas</p>
                            </div>
                        </div>
                    </div>
                </div>
                <HiringBanner />
                <div className='row element-space-40 border-bottom flex-column-reverse flex-md-row'>
                    <div className='col-md-5  mt-4 mt-md-0'>
                        <div className="card p-0">
                            <div className='mb-4'>
                                <h5 className="card-title">Candidate Assessment</h5>
                                <p className="card-text">Administer proctored tests at record low cost</p>
                            </div>
                            <div className='mb-4'>
                                <h5 className="card-title">Outsource interviews</h5>
                                <p className="card-text">Let industry experts share your interview workload reliably</p>
                            </div>
                            <div className=''>
                                <h5 className="card-title">Custom AI toolkit</h5>
                                <p className="card-text">Get custom AI solutions designed for your unique hiring strategy</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-7 pe-md-5'>
                        <img src='../../images/hiring-support.svg' className='mw-100' />
                    </div>
                </div>

                <div className='row border-bottom py-5'>
                    <div className='col-md-7 pe-md-5'>
                        <img src='../../images/employee-training.svg' className='mw-100' />
                    </div>
                    <div className='col-md-5  mt-4 mt-md-0'>
                        <div className="card p-0">
                            <div className='mb-4'>
                                <h5 className="card-title">Experiential Learning</h5>
                                <p className="card-text">Self-paced, task-based learning for your job-role</p>
                            </div>
                            <div className='mb-4'>
                                <h5 className="card-title">Training Content</h5>
                                <p className="card-text">Zero cost personalized training content for each employee</p>
                            </div>
                            <div className=''>
                                <h5 className="card-title">Skill Validation</h5>
                                <p className="card-text">Verify learning loop closure to unlock higher productivity</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFeatureImageDetails;