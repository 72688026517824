import React, { useEffect } from 'react'
import Base from '../../Components/Layout/Base';
import "./style.css";
import HomeBanner from './Component/HomeBanner';
import HomeFeatureCard from './Component/HomeFeatureCard';
import HomeFeatureImageDetails from './Component/HomeFeatureImageDetails';
import HomeFeatureCardSkill from './Component/HomeFeatureCardSkill';
import ClientCarousel from './Component/ClientCarousel';
import Team from './Component/Team';
import Testimonial from './Component/Testimonial';
import Frequently from './Component/Frequently';
import Offer from './Component/Offer';
import ApplicationBanner from './Component/ApplicationBanner';
import { TeamMembers } from '../../helper/data';
import { useLocation } from 'react-router-dom';
import Awards from './Component/Awards';

const Index = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const fragment = location.hash.slice(1);
      const targetElement = document.getElementById(fragment);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Base>
      <HomeBanner />
      <div className='py-5 mt-5' id="college">
        <div className='page-title element-space-30  container montserrat'>
          <h6>OUR SERVICES FOR COLLEGES</h6>
          <h2>
            Unlock Campus Placement Improvements Faster with <span>masterNAUKRI</span> by KidEx
          </h2>
        </div>
        <Offer />
        <HomeFeatureCard />
        <div className='card-detail-txt montserrat'>
          <h2>Let’s connect for a custom solution for your college!</h2>
          <a href='/#contact' className='site-btn blue-btn text-decoration-none d-inline-block'>Contact Us</a>
        </div>
      </div>
      <div className='py-5' id="company">
        <div className='page-title element-space-30 container montserrat'>
          <h6>OUR SERVICES FOR COMPANIES</h6>
          <h2>
            Discover & Hire <span>Top Talent</span> Quickly & Conveniently
          </h2>
        </div>
        <HomeFeatureImageDetails />
        <div className='card-detail-txt montserrat'>
          <h2>Ready to hire or engage?</h2>
          <a href='/#contact' className='site-btn blue-btn text-decoration-none d-inline-block'>Contact Us</a>
        </div>
      </div>
      <div className='py-5' id="individual">
        <div className='page-title element-space-30 container montserrat w-100'>
          <h6>OUR SERVICES FOR STUDENTS</h6>
          <h2>
            Impress potential recruiters by <span>Showcasing Skills</span>
          </h2>
        </div>
        <HomeFeatureCardSkill />
        <ApplicationBanner />
      </div>
      <div className='py-5 mt-5 blue-bg'>
        <div className='page-title  container montserrat w-100'>
          <h2 className='text-white'>
            Our Impact <span>Empowering</span> Future Professionals
          </h2>
          <p className='w-50 mx-auto'>masterNAUKRI by KidEx has successfully enabled & connected youth with relevant employment opportunities</p>
        </div>
        <div className='container text-center mt-4'><img src='../../images/impact_img_new.svg' className='w-50' /></div>
      </div>
      <div className='py-5 mt-5 client-logo-carousel'>
        <div className='page-title element-space-30 container montserrat w-100'>
          <h2>
            Trusted by Leading Companies & Colleges
          </h2>
          <p className='w-50 mx-auto mb-0'>We collaborate with a diverse range of industries and educational institutions to get talent started upon relevant opportunities</p>
        </div>
        <ClientCarousel />
      </div>
      <div className='py-5 client-logo-carousel'>
        <div className='page-title  container montserrat w-100 element-space-30'>
          <h2>Awards & Recognition</h2>
        </div>
        <Awards />
      </div>
      <div className="team py-5 client-logo-carousel" id="team">
        <div className='page-title element-space-30 container montserrat w-100'>
          <h2>
            Meet Our Leadership Team
          </h2>
          <p className='w-50 mx-auto mb-0'>Individuals from top-pedigree colleges & companies committed to transforming Indian talent profile</p>
        </div>
        <Team data={TeamMembers} />
      </div>
      <div className='py-5 mb-5'>
        <div className='page-title element-space-30 container montserrat w-100'>
          <h2>
            What Our Users Say
          </h2>
        </div>
        <Testimonial />
      </div>
      {/* <div className='py-5'>
        <div className='page-title  container montserrat w-100'>
          <h2>
            What Our Users Say
          </h2>
        </div>
        <Frequently />
      </div> */}
    </Base>
  )
}

export default Index;