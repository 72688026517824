import React from 'react'
import linkedInIcon from "../../../Assets/logos/icon-linkdin.svg"
import { Link } from 'react-router-dom'

const Team = ({ data }) => {
  return (
    <div className='featured-cards team-sec montserrat'>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          {data?.map(item => {
            return (
              <div className='col-6 col-md-3 mb-3 mb-md-0'>
                <div className="card">
                  <img className="object-fit-cover" src={item?.image} alt="" />
                  <div class="card-body d-flex justify-content-between">
                    <div>
                      <h5 className="card-title text-start">{item?.name}</h5>
                      <p className="card-text mb-0 text-start">{item?.designation}</p>
                      <p className="card-text text-start fw-normal">{item?.text}</p>
                    </div>
                    <div><Link to={item?.linkedInUrl} target="_blank"><img src={linkedInIcon} alt="icon" /></Link></div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Team;